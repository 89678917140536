<template>
  <div class="tabs-and-stats">
    <div class="tabs">
      <button
        @click="currentTab = 'corrections'"
        :class="{ 'active-tab': currentTab === 'corrections' }"
      >
        Corrections
      </button>
      <button
        @click="currentTab = 'userStats'"
        :class="{ 'active-tab': currentTab === 'userStats' }"
      >
        User Statistics
      </button>
      <button
        @click="currentTab = 'datasetReport'"
        :class="{ 'active-tab': currentTab === 'datasetReport' }"
      >
        Dataset Report
      </button>
      <button
        @click="currentTab = 'search'"
        :class="{ 'active-tab': currentTab === 'search' }"
      >
        Search
      </button>
      <button
        @click="currentTab = 'aiConfig'"
        :class="{ 'active-tab': currentTab === 'aiConfig' }"
      >
        AI Config
      </button>
      <button
        @click="currentTab = 'samplerConfig'"
        :class="{ 'active-tab': currentTab === 'samplerConfig' }"
      >
        Sampler Config
      </button>
      <button
        @click="currentTab = 'pokes'"
        :class="{ 'active-tab': currentTab === 'pokes' }"
      >
        Pokes
      </button>
      <button
        @click="currentTab = 'dpoStats'"
        :class="{ 'active-tab': currentTab === 'dpoStats' }"
      >
        DPO Stats
      </button>
      <!-- <button
        @click="currentTab = 'llmPerformance'"
        :class="{ 'active-tab': currentTab === 'llmPerformance' }"
      >
        LLM Performance
      </button> -->
    </div>
    <div class="stats">
      <div class="stat-item">
        <span class="stat-value">{{ high_quality_corrections }}</span>
        <span class="stat-label">High Quality</span>
      </div>
      <div class="stat-item">
        <span class="stat-value">{{ low_quality_corrections }}</span>
        <span class="stat-label">Low Quality</span>
      </div>
      <div class="stat-item">
        <span class="stat-value">{{ images_labeled }}</span>
        <span class="stat-label">Images Labeled</span>
      </div>
    </div>
  </div>
  <div class="supervisor-container">

    <!-- Manager List Section -->
    <div class="manager-list-section">

    <div class="manager-list-container" v-if="isManagerListVisible">
      
      <!-- <div class="manager-list-container"> -->
        <h1>Managers</h1>
        <ul class="manager-list">
          <li v-for="username in managerUsernames" :key="username"
          :class="{ 'selected-manager': username === selectedManager }">
            <a href="#" @click.prevent="fetchManagerCorrections(username)">
              {{ username }}
            </a>
          </li>
        </ul>
      <!-- </div> -->

    </div>
    
    </div>
    <!-- Corrections Display Section -->
    <div v-if="currentTab === 'corrections'"> 
      <div class="corrections-container" >
      <!-- <h2 v-if="selectedManager">Corrections for {{ selectedManager }}</h2> -->
      <div v-if="loadingCorrections" class="center-message">
        <p>Loading...</p> <!-- This is your loading message or spinner -->
      </div>
      <div v-else>
        <div v-if="managerCorrections && managerCorrections.length > 0">
          <div class="table-container">
            <table>
            <thead>
              <tr>
                <!-- <th>Convo ID</th> -->
                <th class="small-column">Role</th>
                <th>Original Content</th>
                <th>Corrected Content</th>
                <!-- <th>Is Poke</th> -->
                <!-- <th>Grammatically Correct</th> -->
                <!-- <th>Content Fixed Grammar</th> -->
                <!-- <th>Timestamp</th> -->
                <th class="small-column">Image URL</th>
                <th class="small-column">Corrected</th>
                <!-- <th>Is Image Labeled</th> -->
                <th>Image Label</th>
                <!-- <th>Was High Quality</th>
                <th>Was Low Quality</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="correction in groupedCorrections[Object.keys(groupedCorrections)[currentPage]]" :key="correction.id">
                <!-- <td>{{ correction.conversation }}</td> -->
                <!-- <td>{{ correction.role }}</td> -->
                <td class="small-column">{{ correction.role === 'user' ? 'Client' : 'Operator' }}</td>
                <td>{{ correction.content }}</td>
                <td :class="{
                      'high-quality': correction.was_high_quality,
                      'low-quality': correction.was_low_quality && !correction.was_high_quality
                    }">
                  {{ correction.corrected_content }}
                </td>
                <!-- <td>{{ correction.is_poke }}</td> -->
                <!-- <td>{{ correction.is_grammatically_correct }}</td> -->
                <!-- <td>{{ correction.content_fixed_grammar }}</td> -->
                <!-- <td>{{ new Date(correction.timestamp).toLocaleString() }}</td> -->
                <td class="small-column">
                  <a :href="correction.image_url" target="_blank" v-if="correction.image_url">View Image</a>
                  <span v-else>N/A</span>
                </td>
                <td class="small-column">{{ correction.is_corrected }}</td>
                <!-- <td>{{ correction.is_image_labeled }}</td> -->
                <td>{{ correction.image_label }}</td>
                <!-- <td>{{ correction.was_high_quality }}</td> -->
                <!-- <td>{{ correction.was_low_quality }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
          
        </div>
        <div v-else-if="selectedManager">
          <p>No corrections available for this manager.</p>
        </div>

        <div v-else>
          <div class="center-message">
            <p>Choose a manager from the left.</p>
          </div>
        </div>

        <div class="pagination-container">
          <div class="left-pagination-section">
            <span class="current-conversation-id">
            Current Conv. ID: {{ getCurrentConversationId() }}
            </span>
            <div class="page-navigation">
                <input type="number" v-model="desiredPage" min="1" :max="totalPages" @keyup.enter="goToPage" class="page-input" placeholder="Go to page...">
                <button @click="goToPage" class="go-button">Go</button>
            </div>
          </div>
          <div class="pagination" ref="paginationContainer">
            <button
              v-for="(group, index) in Object.keys(groupedCorrections)"
              :key="index"
              @click="setCurrentPage(index)"
              :class="{'active-page': currentPage === index}">
              {{ index + 1 }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <user-statistics v-else-if="currentTab === 'userStats'"></user-statistics>
  <dataset-statistics v-else-if="currentTab === 'datasetReport'"> </dataset-statistics>
  <search-page v-else-if="currentTab === 'search'"></search-page>
  <ai-config-page v-if="currentTab === 'aiConfig'"></ai-config-page>
  <sampler-config-page v-else-if="currentTab === 'samplerConfig'"></sampler-config-page>
  <pokes-page v-if="currentTab === 'pokes'"></pokes-page>
  <dpo-stats-page v-if="currentTab === 'dpoStats'"></dpo-stats-page>
  <!-- <llm-performance-page v-if="currentTab === 'llmPerformance'"></llm-performance-page> -->
  </div>
</template>

<script>
import UserStatistics from './UserStatistics.vue';
import DatasetStatistics from './DatasetStatistics.vue';
import SearchPage from './SearchPage.vue';
import AiConfigPage from './AiConfigPage.vue';
import SamplerConfigPage from './SamplerConfigPage.vue';
import PokesPage from './PokesPage.vue';
import DpoStatsPage from './DpoStatsPage.vue';

export default {
  components: {
    UserStatistics,
    DatasetStatistics,
    SearchPage,
    AiConfigPage,   
    SamplerConfigPage,
    PokesPage,
    DpoStatsPage
  },
  name: 'SupervisorPage',
  data() {
    return {
        token: null,
        managerUsernames: [],
        managerCorrections: null,
        selectedManager: null,
        // isManagerListVisible: true,
        currentPage: 0,
        groupedCorrections: {},
        currentTab: 'corrections',
        loadingCorrections: false,
        high_quality_corrections: 0,
        low_quality_corrections: 0,
        images_labeled: 0,
        };
  },
  methods: {
    getToken(){
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async fetchManagerUsernames() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_managers/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
        });
        if (response.ok) {
          this.managerUsernames = await response.json();
        } else {
          console.error('Error fetching manager usernames');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async fetchManagerCorrections(username) {
      this.selectedManager = username;
      this.loadingCorrections = true; 
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_manager_corrections/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({ username })
        });
        if (response.ok) {
          this.managerCorrections = await response.json();
          console.log('MANAGER CORRECTIONS:', this.managerCorrections);
          // Handle display of corrections here or via another method/component
          this.fetchManagerStats(username)
        } else {
          console.error('Error fetching manager corrections');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.loadingCorrections = false; // Done loading, whether success or error
      }
    },
    async fetchManagerStats(managerUsername) {
      try {
        const url = new URL(`${process.env.VUE_APP_API_BASE_URL}/api/users/get_manager_stats/`);
        // Add query parameters to the URL
        if (managerUsername) {
          url.searchParams.append('manager_username', managerUsername);
        }
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
        });
        if (response.ok) {
          const stats = await response.json();
          console.log('Manager stats:', stats);
          this.high_quality_corrections = stats.high_quality_corrections;
          this.low_quality_corrections = stats.low_quality_corrections;
          this.images_labeled = stats.images_labeled;
        } else {
          console.error('Error fetching manager stats');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },

    groupCorrectionsByConversationId() {
      const grouped = {};
      // for (let i = 1; i <= 1000; i++) {
      //   const fakeConversationId = `conversation_${i}`;
      //   grouped[fakeConversationId] = [{
      //     // Mock correction data
      //     id: `correction_${i}`,
      //     conversation: fakeConversationId,
      //     content: 'Test content',
      //     corrected_content: 'Test corrected content',
      //     role: 'role',
      //     image_url: '',
      //     is_corrected: false,
      //     image_label: '',
      //     was_high_quality: false,
      //     was_low_quality: false
      //   }];
      // }

      if(this.managerCorrections) {
        this.managerCorrections.forEach(correction => {
        if (!grouped[correction.conversation]) {
          grouped[correction.conversation] = [];
        }
        grouped[correction.conversation].push(correction);
      });
      }
      this.groupedCorrections = grouped;
      console.log("GROUPED CONVERSATIONS:", this.groupedCorrections);
      this.currentPage = 0; // Reset to the first page
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    getCurrentConversationId() {
      const conversationIds = Object.keys(this.groupedCorrections);
      return conversationIds[this.currentPage] || 'N/A';
    },
    goToPage() {
        const page = parseInt(this.desiredPage, 10) - 1; // Convert to zero-based index
        if (!isNaN(page) && page >= 0 && page < this.totalPages) {
            this.setCurrentPage(page);
            this.scrollToPage(page);
        } else {
            alert('Invalid page number');
        }
    },
    scrollToPage(pageIndex) {
        const buttonWidth = 55;
        const scrollPosition = pageIndex * buttonWidth;
        this.$refs.paginationContainer.scrollLeft = scrollPosition;
    },
    loadCurrentTab() {
      const savedTab = localStorage.getItem('currentTab');
      if (savedTab) {
        this.currentTab = savedTab;
      }
    },
    saveCurrentTab() {
      localStorage.setItem('currentTab', this.currentTab);
    },
  },
  computed: {
      totalPages() {
          return Object.keys(this.groupedCorrections).length;
      },
      isManagerListVisible() {
        return this.currentTab === 'corrections';
      },
  },
  watch: {
    managerCorrections: {
        immediate: true,
        handler() {
          this.groupCorrectionsByConversationId();
        },
      },
      currentTab() {
        this.saveCurrentTab();
      },
    },
  mounted() {
    this.getToken();
    this.fetchManagerUsernames();
    this.loadCurrentTab();
  }
};
</script>

<style scoped>
.supervisor-container {
  display: flex;
  flex-direction: row;
  /* padding-top: 45.5px; */
}

.manager-list-section {
  border-right: 1px solid #ccc; /* Separator line */
}

.manager-list-container {
  position: sticky;
  top: 0;
  overflow-y: auto; /* Enable vertical scrolling */
  max-height: 100vh; /* Set a maximum height (adjust as needed) */
  border-right: 1px solid #ccc; /* Separator line */
}

.manager-list {
  list-style-type: none;
  padding: 0;
}

.manager-list li {
  margin-bottom: 10px;
}

.manager-list li a {
  color: #0056b3;
  text-decoration: none;
  display: block;
  padding: 5px 10px; /* Adjust padding for each list item */
}

.corrections-container {
  flex-grow: 1;
  height: calc(100vh - 60px); /* Adjust height to account for pagination height */
  overflow-y: auto;
}

.corrections-container table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.corrections-container th, 
.corrections-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.corrections-container thead th {
  background-color: black;
  position: sticky;
  top: 0;
  z-index: 10;
}

/* Styles for scrolling in the table body if needed */
.corrections-container tbody {
  max-height: 100vh; /* Adjust based on your requirement */
  overflow-y: auto;
}

/* Ensure table cells are not shrunk when tbody is a block */
.corrections-container td {
  min-width: 120px; /* Adjust based on content */
}

.table-container {
  max-height: calc(100vh - 60px);
  overflow-y: auto;
}

.high-quality {
  background-color: lightgreen; /* Adjust the color as needed */
}

.low-quality {
  background-color: yellow; /* Adjust the color as needed */
}

table {
    margin-top: 0;
}

.selected-manager {
  background-color: #e0f7fa;
}

.center-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Full height of the viewport */
  text-align: center;
  width: 90vw;
}

.center-message p {
  font-size: 3em; /* Adjust the font size as needed */
  margin: 0; /* Remove default margin */
}

.pagination {
  display: flex;
  overflow-x: auto;
  white-space: nowrap; /* Prevent buttons from wrapping onto new lines */
  margin-right: 20px;
  padding: 5px 0; /* Adjust padding as needed */
}

.pagination button {
  flex: 0 0 auto; /* Prevent buttons from stretching */
  padding: 5px 10px;
  margin-right: 5px; /* Space between buttons */
  background-color: #f0f0f0;
  border: 1px solid #dcdcdc;
  cursor: pointer;
  width: 45px;
}

.pagination .active-page {
  background-color: #0056b3;
  color: white;
}

.pagination-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  /* padding: 10px 0; */
  box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  height: 60px;
}

.left-pagination-section {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.current-conversation-id {
  margin-left: 20px;
  min-width: 150px;
}

.page-navigation {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.page-input {
    padding: 5px;
    margin-right: 10px;
    width: 120px;
}

.go-button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

.small-column {
  width: 90px; 
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs button.active-tab {
  background-color: #007bff;
  color: white;
}

.tabs button {
  padding: 10px;
  border: none;
  background-color: #616161;
  cursor: pointer;
}

.tabs {
  top: 0px;
  left: 0px;
  width: 100vw;
  position: relative;
}
.active-tab {
  background-color: #007bff; /* Bootstrap primary blue */
  color: white; /* White text color */
  /* Additional styling for the active tab button */
  border-color: #007bff;
}

.tabs-and-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 5px;
}

.stats {
  display: flex;
  align-items: center;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px; /* Adjust spacing as needed */
}

.stat-value {
  font-weight: bold;
}

.stat-label {
  font-size: smaller;
}

body {
  overflow: hidden; /* This disables scrolling for the entire page */
  height: 100vh; /* This limits the height to the viewport height */
  margin: 0;
}

.manager-list-container h1 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}


</style>